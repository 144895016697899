import { useEffect, useState } from "react";

const Style = ({ attributes }) => {
  const { styles, uniqueId, woo, progressBar, additional } = attributes;
  const { progressBarColor, thumbSize = "90px" } = styles;

  const [CSS, setCSS] = useState(null);

  useEffect(() => {
    const CSS = `
        #${uniqueId} {
            width: ${`${styles?.width?.desktop || styles.width}`};
            height: calc(${styles?.height?.desktop || styles.height} + 100px);
        }
        .pop-up-content-wrap #${uniqueId} {
            height: ${styles?.height?.desktop || styles.height};
        }
        #${uniqueId} .select {display: none}
        #${uniqueId} .b3dviewer-wrapper {
            height: ${styles?.height?.desktop || styles.height};
        }

        #${uniqueId} model-viewer {
            background: ${styles?.bgColor} ${styles?.bgImage ? `url(${styles?.bgImage})` : " "} no-repeat center center; 
            background-size: cover;
        }
        #${uniqueId} model-viewer::part(default-progress-bar) {
             display: ${!progressBar ? "none" : "block"}
        }
        @media screen and (max-width: 768px){
            #${uniqueId} {
                width: ${woo ? "" : `${styles?.width.tablet || styles.width}`};
                height: ${styles?.height.tablet || styles.height};
            }
        }
        @media screen and (max-width: 480px){
            #${uniqueId} {
                width: ${woo ? "" : `${styles?.width.mobile || styles.width}`};
                height: ${styles?.height.mobile || styles.height};
            }
        }
        #${uniqueId} model-viewer::part(default-progress-bar){
            background: ${progressBarColor};
        }
        #${uniqueId} .slides .slide{
            width: ${thumbSize};
            height: ${thumbSize};
            padding: calc(${thumbSize} * 0.04);
        }
        #${uniqueId} .slide-number span{
            font-size: calc(${thumbSize} * 0.8);
        }
        ${additional?.CSS}
    `.replaceAll(/\n?\s\s/g, "");
    setCSS(CSS);
  }, [uniqueId, styles, additional, progressBar]);

  return <style>{CSS}</style>;
};

export default Style;
