import { textureChannels } from './blocks/3d-viewer/data';
import ModelReader from './ModelReader';
import { produce } from "immer";

export function getParams(url = "") {
  url = url || location?.href;
  var queryStart = url.indexOf("?") + 1,
    queryEnd = url.indexOf("#") + 1 || url.length + 1,
    query = url.slice(queryStart, queryEnd - 1),
    pairs = query.replace(/\+/g, " ").split("&"),
    parms = {},
    i,
    n,
    v,
    nv;

  if (query === url || query === "") return;

  for (i = 0; i < pairs.length; i++) {
    nv = pairs[i].split("=", 2);
    n = decodeURIComponent(nv[0]);
    v = decodeURIComponent(nv[1]);

    // eslint-disable-next-line no-prototype-builtins
    if (!parms.hasOwnProperty(n)) parms[n] = [];
    parms[n] = nv.length === 2 ? v : null;
  }
  return parms;
}

export function restoreOriginalImageSrc(imageSrc) {
  // Define a regular expression pattern to match sizes like "-100x100", "-200x200", etc.
  const sizePattern = /-\d{2,4}x\d{2,4}/g;

  // Remove any size string from the image src and the hyphen before it
  const originalSrc = imageSrc.replace(sizePattern, "");

  return originalSrc;
}

export function isImageSource(str) {
  // Regular expression to match common image file extensions
  const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i;

  // Check if the string matches the image extension pattern
  return imageExtensions.test(str);
}

export function findParentUntilMultipleChildren(element) {
  let parent = element.parentElement;

  while (parent && parent.children.length === 1) {
    element = parent;
    parent = parent.parentElement;
  }

  return element;
}

export function findParentAnchorTag(element) {
  let parent = element.parentElement;

  while (parent && parent.tagName === "A") {
    element = parent;
    parent = parent.parentElement;
  }

  return element;
}

export function getMaterials(materials) {
  if (Array.isArray(materials)) {
    return materials.map((item) => item.name);
  }
  return [];
}

export function getMaterialsNameForSelectControl(materials = []) {
  if (Array.isArray(materials)) {
    return materials.map((item) => {
      const label = item.name.replace("_mtl", "").replaceAll("_", " ");
      return { label, value: item.name };
    });
  }
  return [];
}

export function rgbaToFactor(r, g, b, a = 255) {
  return [r / 255, g / 255, b / 255, a / 255];
}

export function sRGBToLinear(value) {
  value /= 255;
  return value <= 0.04045 ? value / 12.92 : ((value + 0.055) / 1.055) ** 2.4;
}

export function rgbaStringToFactor(rgbaString) {
  if(!['rgba', 'rgb'].includes(isRGBorRGBA(rgbaString)) ){
    return rgbaString;
  }
  const match = rgbaString.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/);
  if (!match) return null;

  const [_, r, g, b, a] = match.map(Number);
  return [sRGBToLinear(r), sRGBToLinear(g), sRGBToLinear(b), a]; // Convert RGB, keep alpha
}

export function factorToRgbaString(factor) {
  if (!Array.isArray(factor) || (factor.length !== 3 && factor.length !== 4)) {
      return factor;
  }

  const r = Math.round(factor[0] * 255);
  const g = Math.round(factor[1] * 255);
  const b = Math.round(factor[2] * 255);
  const a = factor.length === 4 ? factor[3] : 1; // Default alpha to 1 if not present

  return factor.length === 4 ? `rgba(${r}, ${g}, ${b}, ${a})` : `rgb(${r}, ${g}, ${b})`;
}

export function rgbaToHex(rgba) {
  // Extract the RGBA values from the string
  try {
    const rgbaValues = rgba.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([0-9.]+)?\)/);

  if (!rgbaValues) {
    throw new Error("Invalid RGBA color string");
  }

  // Parse the R, G, B values
  const r = parseInt(rgbaValues[1]);
  const g = parseInt(rgbaValues[2]);
  const b = parseInt(rgbaValues[3]);

  // Parse the alpha value, default to 1 if not provided
  const a = rgbaValues[4] !== undefined ? parseFloat(rgbaValues[4]) : 1;

  // Convert to hex
  const toHex = (n) => n.toString(16).padStart(2, "0").toUpperCase();

  // Convert alpha to hex (if needed)
  const alphaHex = toHex(Math.round(a * 255));

  // Return hex with or without alpha
  return `#${toHex(r)}${toHex(g)}${toHex(b)}${a < 1 ? alphaHex : ""}`;
  } catch (error) {
    return null;
  }
}

export function getAllMaterialData(model) {
  if (!model.model) {
      console.warn("Model not loaded yet.");
      return {};
  }

  const materialsData = {};

  model.model.materials.forEach((material) => {
      const materialName = material.name || `Material_${Math.random().toString(36).substr(2, 5)}`; // Ensure a unique key if no name exists

      materialsData[materialName] = {
          baseColor: {
              texture: material.pbrMetallicRoughness.baseColorTexture?.texture?.source?.uri || null,
              factor: material.pbrMetallicRoughness.baseColorFactor || [1, 1, 1, 1], // Default to white
              default: {
                texture: material.pbrMetallicRoughness.baseColorTexture?.texture?.source?.uri || null,
                factor: material.pbrMetallicRoughness.baseColorFactor || [1, 1, 1, 1], // Default to white
              }
          },
          metallicRoughness: {
              texture: material.pbrMetallicRoughness.metallicRoughnessTexture?.texture?.source?.uri || null,
              uri: material.pbrMetallicRoughness.metallicRoughnessTexture?.texture?.source?.uri || null,
              textureName: material.pbrMetallicRoughness.metallicRoughnessTexture?.texture?.source?.textureName || null,
              factor: [
                  material.pbrMetallicRoughness.metallicFactor || 1, 
                  material.pbrMetallicRoughness.roughnessFactor || 1
              ],
              default: {
                uri: material.pbrMetallicRoughness.metallicRoughnessTexture?.texture?.source?.uri || null,
                textureName: material.pbrMetallicRoughness.metallicRoughnessTexture?.texture?.source?.textureName || null,
                factor: [
                    material.pbrMetallicRoughness.metallicFactor || 1, 
                    material.pbrMetallicRoughness.roughnessFactor || 1
                ],
              }
          },
          normal: {
              texture: material.normalTexture?.texture?.source?.uri || null,
              default: {
                texture: material.normalTexture?.texture?.source?.uri || null,
              }
          },
          emissive: {
              texture: material.emissiveTexture?.texture?.source?.uri || null,
              factor: material.emissiveFactor || [0, 0, 0], // Default to black
              default: {
                texture: material.emissiveTexture?.texture?.source?.uri || null,
                factor: material.emissiveFactor || [0, 0, 0], // Default to black
              }
          },
          occlusion: {
              texture: material.occlusionTexture?.texture?.source?.uri || null,
              default: {
                texture: material.occlusionTexture?.texture?.source?.uri || null,
              }
          },
      };
  });
  return materialsData;
}


export function isRGBorRGBA(color) {
  const rgbRegex = /^rgb\(\s*\d{1,3}\s*,\s*\d{1,3}\s*,\s*\d{1,3}\s*\)$/i;
  const rgbaRegex = /^rgba\(\s*\d{1,3}\s*,\s*\d{1,3}\s*,\s*\d{1,3}\s*,\s*(0|1|0?\.\d+)\s*\)$/i;

  if (rgbaRegex.test(color)) return "rgba";
  if (rgbRegex.test(color)) return "rgb";
  return false; // Not a valid RGB/RGBA string
}

export const createAndApplyTexture = async (model, material, channel, value, name = "raju" + new Date().getTime()) => {
  const texture = await model.createTexture(value);
  texture.name = name;
  if (channel.includes("base") || channel.includes("metallic")) {
    material.pbrMetallicRoughness[channel+'Texture'].setTexture(texture);
  } else {
    material[channel+'Texture'].setTexture(texture);
  }
};


export const applyTexture =  async (model, appliedTextures) => {
  const modelReader = new ModelReader(model);
  window.modelReader = modelReader;
  const textures = await modelReader.getTextures();
  const texturesArray = modelReader.getTexturesArray();


    Object.keys(appliedTextures).map(materialName => {
      const material = model?.model?.getMaterialByName(materialName);

      if(!material) {
        return;
      }
      
      textureChannels.map(item => {
        const channel = item?.value || null;

        if(!channel){
          return;
        }

        const {name, index} = appliedTextures[materialName]?.[channel]?.texture || {};
        const textureImage = textures[texturesArray[index]] || textures[name];
        const factor = appliedTextures[materialName]?.[channel]?.factor || null;
        if(textureImage) {

          createAndApplyTexture(model, material, channel,textureImage, name);
        }

        if (factor) {
          if(channel === 'baseColor'){
            material.pbrMetallicRoughness.setBaseColorFactor(rgbaStringToFactor(factor));
          }
          if(channel === 'emissive'){
            material.setEmissiveFactor(rgbaStringToFactor(factor));
          }
        }
      
      })
    })
}




export function resetMaterialProperty(defaultMaterialData, materialName, channel, property) {
  if(!defaultMaterialData[materialName][channel].default){
    return defaultMaterialData;
  }
    if (!defaultMaterialData[materialName]) {
        console.warn(`Material '${materialName}' not found in default data.`);
        return defaultMaterialData;
    }

    if (!defaultMaterialData[materialName][channel]) {
        console.warn(`Channel '${channel}' not found for material '${materialName}'.`);
        return defaultMaterialData;
    }

    if (property !== "factor" && property !== "texture") {
        console.warn(`Invalid property '${property}'. Use 'factor' or 'texture'.`);
        return defaultMaterialData;
    }

    return produce(defaultMaterialData, (draft) => {
        if (property === "factor") {
            draft[materialName][channel].factor = draft[materialName][channel].default.factor;
        } else if (property === "texture") {
            draft[materialName][channel].texture = draft[materialName][channel].default.texture;
        }
    });
}


export function merge(target, source) {
  // If source is not an object, return a copy of the target as is
  if (typeof source !== 'object' || source === null) {
      return { ...target };
  }

  // If the target is not an object, create a new object
  if (typeof target !== 'object' || target === null) {
      target = Array.isArray(source) ? [] : {};
  }

  // Create a new object to store merged results
  const result = Array.isArray(target) ? [] : {};

  // Merge target and source
  for (const key in target) {
      if (Object.prototype.hasOwnProperty.call(target, key)) {
          result[key] = target[key];
      }
  }

  for (const key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
          if (typeof source[key] === 'object' && source[key] !== null) {
              // Recursively merge if both source and target values are objects
              result[key] = merge(result[key], source[key]);
          } else {
              // Directly assign value from the source
              result[key] = source[key];
          }
      }
  }

  return result;
}


export function debounce(callback, delay) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(this, args); // Preserve the correct `this` context
    }, delay);
  };
}