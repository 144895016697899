export function getParams(url = "") {
  url = url || location?.href;
  var queryStart = url.indexOf("?") + 1,
    queryEnd = url.indexOf("#") + 1 || url.length + 1,
    query = url.slice(queryStart, queryEnd - 1),
    pairs = query.replace(/\+/g, " ").split("&"),
    parms = {},
    i,
    n,
    v,
    nv;

  if (query === url || query === "") return;

  for (i = 0; i < pairs.length; i++) {
    nv = pairs[i].split("=", 2);
    n = decodeURIComponent(nv[0]);
    v = decodeURIComponent(nv[1]);

    if (!parms.hasOwnProperty(n)) parms[n] = [];
    parms[n] = nv.length === 2 ? v : null;
  }
  return parms;
}

export function restoreOriginalImageSrc(imageSrc) {
  // Define a regular expression pattern to match sizes like "-100x100", "-200x200", etc.
  const sizePattern = /-\d{2,4}x\d{2,4}/g;

  // Remove any size string from the image src and the hyphen before it
  const originalSrc = imageSrc.replace(sizePattern, "");

  return originalSrc;
}

export function isImageSource(str) {
  // Regular expression to match common image file extensions
  const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i;

  // Check if the string matches the image extension pattern
  return imageExtensions.test(str);
}

export function findParentUntilMultipleChildren(element) {
  let parent = element.parentElement;

  while (parent && parent.children.length === 1) {
    element = parent;
    parent = parent.parentElement;
  }

  return element;
}
