import { useEffect, useRef, useState } from "react";

import posx from "../../../public/images/envmaps/fishermans_bastion/posx.jpg";
import posy from "../../../public/images/envmaps/fishermans_bastion/posy.jpg";
import posz from "../../../public/images/envmaps/fishermans_bastion/posz.jpg";
import negx from "../../../public/images/envmaps/fishermans_bastion/negx.jpg";
import negy from "../../../public/images/envmaps/fishermans_bastion/negy.jpg";
import negz from "../../../public/images/envmaps/fishermans_bastion/negz.jpg";
import { hexToRGB } from "../../../../wp-utils/v1";
import SliderController from "./Components/SliderControllder";
import ModelPagination from "./Components/ModelPagination";

function copyObjectUsingJSON(originalObject) {
  return JSON.parse(JSON.stringify(originalObject));
}

const Button = (props) => {
  return <button {...props}></button>;
};

const Basic3DViewer = (props) => {
  const { attributes, currentItem, setCurrentItem, modelSrc, setAttributes, __ } = props;
  const { styles, models, multiple, O3DVSettings } = attributes;
  const { isNavigation, isPagination, camera, mouseControl } = O3DVSettings;

  const [viewerLoaded, setViewerLoaded] = useState(false);

  const parentDiv = useRef(null);
  const viewerRef = useRef(null);

  useEffect(() => {
    if (modelSrc) {
      viewerRef.current?.Destroy();
      viewerRef.current?.canvas?.remove();
      viewerRef.current = null;
      if (viewerRef.current === null) {
        OV.SetExternalLibLocation("../libs");

        const defaultCamera =
          camera && !multiple
            ? { camera: new OV.Camera(new OV.Coord3D(...Object.values(camera.eye)), new OV.Coord3D(...Object.values(camera.center)), new OV.Coord3D(...Object.values(camera.up)), 45.0) }
            : {};

        let viewer = new OV.EmbeddedViewer(parentDiv.current, {
          ...defaultCamera,
          backgroundColor: new OV.RGBAColor(255, 255, 255, 255),
          edgeSettings: new OV.EdgeSettings(false, new OV.RGBColor(0, 0, 0), 1),
          environmentSettings: new OV.EnvironmentSettings([posx, posy, posz, negx, negy, negz], false),
        });

        const models = [modelSrc];
        viewer.LoadModelFromUrlList(models);
        viewerRef.current = viewer;
        window.VR = viewer;
      } else {
        viewerRef.current.LoadModelFromUrlList([modelSrc]);
        const Coord3D = new OV.Coord3D(0.0, 0.0, 0.0);
        viewerRef.current.viewer.SetCamera(new OV.Camera(new OV.Coord3D(0.0, 0.0, 1.0), Coord3D, new OV.Coord3D(0.0, 1.0, 0.0), 45.0));
      }
    }

    return () => {
      if (viewerRef.current !== null && parentDiv.current !== null && viewerLoaded) {
        delete viewerRef.current.model;
        viewerRef.current.viewer.renderer.resetState();
        viewerRef.current.viewer.Clear();
        if (viewerRef.current.viewer) {
          delete viewerRef.current.viewer;
        }
        const gl = viewerRef.current.canvas.getContext("webgl2");
        gl.getExtension("WEBGL_lose_context").loseContext();
        const tempClone = viewerRef.current.canvas.cloneNode(true);
        viewerRef.current.canvas.parentNode.replaceChild(tempClone, viewerRef.current.canvas);
        parentDiv.current?.removeChild(parentDiv.current.children[0]);
        viewerRef.current.canvas?.parentNode?.removeChild(viewerRef.current.canvas);
        viewerRef.current.canvas.remove();
        viewerRef.current = null;
        setViewerLoaded(false);
      }
    };
  }, [modelSrc]);

  useEffect(() => {
    if (styles.bgColor) {
      viewerRef.current?.viewer?.SetBackgroundColor(hexToRGB(styles.bgColor));
    }
    setTimeout(() => {
      viewerRef.current?.viewer?.Resize(parentDiv.current.offsetWidth, parentDiv.current.offsetHeight);
    }, 10);
    viewerRef.current.viewer.Render();
    window.viewer = viewerRef.current;
  }, [styles, modelSrc]);

  // resize if window is resized
  useEffect(() => {
    window.addEventListener("resize", () => {
      viewerRef.current?.viewer?.Resize(parentDiv.current?.offsetWidth, parentDiv.current?.offsetHeight);
    });
  }, []);

  return (
    <>
      <div
        ref={parentDiv}
        role={"img"}
        aria-label="Canvas showing the model in the 3D Viewer"
        className={`${mouseControl ? "" : "DMC"} ${
          viewerRef.current?.modelLoader?.inProgress
        } relative flex  flex-col items-center justify-center p-2 h-72 w-72 border-2 border-black rounded-sm online_3d_viewer`}
        style={{ width: styles.width.desktop || styles.width, height: styles.height.desktop || styles.height }}
      >
        <ModelPagination attributes={attributes} setCurrentItem={setCurrentItem} currentItem={currentItem} isPagination={isPagination} />
        {multiple && isNavigation && models.length > 1 && <SliderController currentItem={currentItem} setCurrentItem={setCurrentItem} models={models} />}
        {setAttributes && !multiple && (
          <div className="setDefaultViewContainer">
            <Button
              variant="primary"
              className="setDefaultView button button-primary"
              onClick={() => {
                if (viewerRef.current) {
                  const camera = copyObjectUsingJSON(viewerRef.current?.viewer?.GetCamera());
                  setAttributes({ O3DVSettings: { ...O3DVSettings, camera } });
                }
              }}
            >
              {camera ? __("Reset Default View", "model-viewer") : __("Set Default View", "model-viewer")}
            </Button>
            {camera && (
              <>
                <Button
                  variant="primary"
                  className="setDefaultView button button-primary"
                  onClick={() => {
                    viewerRef.current?.viewer.SetCamera(
                      new OV.Camera(new OV.Coord3D(...Object.values(camera.eye)), new OV.Coord3D(...Object.values(camera.center)), new OV.Coord3D(...Object.values(camera.up)), 45.0)
                    );
                  }}
                >
                  {__("See Default View", "model-viewer")}
                </Button>
                <Button variant="primary" className="setDefaultView button button-primary" onClick={() => setAttributes({ O3DVSettings: { ...O3DVSettings, camera: null } })}>
                  {__("Remove Default View", "model-viewer")}
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Basic3DViewer;
