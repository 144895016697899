function hexToRGB(hex, type = "string") {

  if (hex === 'transparent') {
    return {
      r: 255,
      g: 255,
      b: 255,
      a: 0
    }
  }

  if (hex.includes('rgba')) {
    const match = hex.match(/rgba?\((\d+),(\d+),(\d+)(?:,([\d.]+))?\)/);
    if (match) {
      const [, r, g, b, a = 1] = match.map(Number);
      return { r, g, b, a };
    }
    return null; // Return null for invalid input
  }

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex.replace("#", ""));
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a: parseInt(result[4], 16) ? parseInt(result[4], 16) : parseInt(result[4], 16) === 0 ? 0 : 255,
    }
    : null;

  return Object.values(rgba).filter((item) => item);
}

export default hexToRGB;
