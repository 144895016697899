import React from "react";

const SliderController = ({ currentItem, setCurrentItem, models }) => {
  return (
    <>
      <div className="slideController">
        <svg fill="#000000" onClick={() => setCurrentItem(currentItem > 0 ? currentItem - 1 : currentItem)} className={`arrow-left ${currentItem > 0 ? "" : "disabled"}`} width="24px" height="24px" viewBox="-78.5 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <title>left</title>
          <path d="M257 64L291 98 128 262 291 426 257 460 61 262 257 64Z" />
        </svg>
        <svg onClick={() => setCurrentItem(currentItem < models.length - 1 ? currentItem + 1 : currentItem)} fill="#000000" className={`arrow-right ${currentItem < models.length - 1 ? "" : "disabled"}`} width="24px" height="24px" viewBox="-77 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <title>right</title>
          <path d="M98 460L64 426 227 262 64 98 98 64 294 262 98 460Z" />
        </svg>
      </div>
    </>
  );
};

export default SliderController;
