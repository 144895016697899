// eslint-disable-next-line react/no-deprecated
import { render } from "react-dom";
import { jsonParse } from "./../../../wp-utils/v1";
import Viewer from "./../blocks/3d-viewer/Components/Viewer";

import "./product.js";
import { findParentUntilMultipleChildren, isImageSource, restoreOriginalImageSrc } from "../utils";

document.addEventListener("DOMContentLoaded", function () {
  const models = document.querySelectorAll(".b3dviewer-wrapper:not(.elementor)");

  Object.values(models).map((wrapper) => {
    modelRenderer({ animation: true, variant: true, wrapper });
  });

  /**
   * Modal/Popup start
   */

  document.querySelectorAll(".bp3dv-model-main")?.forEach((model) => {
    let selector = model.dataset.selector;

    if (!selector || selector === "") {
      return false;
    }
    let clickableItem = document.querySelector(isImageSource(selector) ? `img[src="${selector}"]` : selector.includes("#") || selector.includes(".") ? selector : `.${selector}`);

    if (isImageSource(selector)) {
      if (!clickableItem) {
        clickableItem = document.querySelector(`img[data-src="${selector}"]`);
      }
      if (!clickableItem) {
        clickableItem = document.querySelector(`img[data-src="${restoreOriginalImageSrc(selector)}"]`);
      }
      if (!clickableItem) {
        clickableItem = document.querySelector(`img[src="${restoreOriginalImageSrc(selector)}"]`);
      }
    }

    if (clickableItem) {
      clickableItem = findParentUntilMultipleChildren(clickableItem);
      if (clickableItem?.innerHTML?.includes("<a")) {
        clickableItem.classList.add("bp3dv_a_popup_opener");
      }

      if (clickableItem?.innerHTML?.includes("<img") && window.location.host === "reintest.be") {
        clickableItem.classList.add("bp3dv_play_icon");
      }

      clickableItem?.addEventListener("click", (e) => {
        e.preventDefault();
        model.classList.add("model-open");
      });

      clickableItem.style.cursor = "pointer";

      model.querySelector(".close-btn").addEventListener("click", () => model.classList.remove("model-open"));
      model.querySelector(".bg-overlay").addEventListener("click", () => model.classList.remove("model-open"));
    } else {
      console.warn("clickable item is not found", selector);
    }
  });
});

const FrontEnd = ({ attributes }) => {
  function __(text, textdomain = "") {
    return text;
  }

  if (attributes) {
    return <Viewer attributes={attributes} __={__} />;
  }
};

/**
 * elementor start
 */
window.addEventListener("elementor/frontend/init", function () {
  elementorFrontend.hooks.addAction("frontend/element_ready/3dModelViewer.default", function (scope, $) {
    const blocks = scope[0]?.querySelectorAll(".modelViewerBlock");
    blocks?.forEach((dom) => {
      const dataset = { ...dom.dataset } || {};
      setTimeout(() => {
        Object.keys(dom.dataset).map((key) => delete dom.dataset[key]);
      }, 10);

      const attributes = jsonParse(dataset.attributes);

      if (attributes) {
        const selector = dataset.selector;
        if (selector && document.querySelector(selector)) {
          dom = document.querySelector(selector);
          dom.setAttribute("style", `width:${dom.offsetWidth}px !important;height:350px`);
        }
        if (!attributes?.currentViewer || attributes.currentViewer === "modelViewer") {
          dom.setAttribute("data-rendered", "true");
          render(<FrontEnd attributes={attributes} />, dom);
          const Src = document.getElementById("bp3d-model-viewer-js");
          if (!Src) {
            const script = document.createElement("script");
            script.type = "module";
            script.id = "bp3d-model-viewer-js";
            script.src = window["bp3dBlock"]?.modelViewerSrc;
            document.head.appendChild(script);
          }
        }

        if (attributes.currentViewer === "O3DViewer") {
          if (typeof OV == "undefined") {
            const Src = document.getElementById("bp3d-o3dviewer-js");
            if (!Src) {
              const script = document.createElement("script");
              script.id = "bp3d-o3dviewer-js";
              script.src = window["bp3dBlock"]?.o3dviewerSrc;
              document.head.appendChild(script);
              script.addEventListener("load", () => {
                render(<FrontEnd attributes={attributes} />, dom);
              });
            } else {
              Src.addEventListener("load", () => {
                render(<FrontEnd attributes={attributes} />, dom);
              });
            }
          } else {
            render(<FrontEnd attributes={attributes} />, dom);
          }
        }
      }
    });
  });
});

const createSelector = (element, options = [], selected, show = true) => {
  if (element?.parentElement) {
    if (show) {
      element.parentElement.style.display = "inline-block";
    }
    element.innerHTML = "";
  } else {
    return;
  }
  if (!options.length && element.parentElement) {
    if (window.location.href.includes("wp-admin")) {
      options[0] = "not available";
    } else {
      element.parentElement.style.display = "none";
    }
  }
  for (const name of options) {
    const option = document.createElement("option");
    option.value = name;
    option.textContent = name;
    element.appendChild(option);
    if (name === selected) {
      option.selected = true;
    }
  }
};

function modelRenderer(params = {}) {
  const { animation = false, variant = false, wrapper } = params;
  if (!wrapper) {
    return;
  }

  //model viewer
  const modelViewer = wrapper.querySelector("model-viewer");
  if (!modelViewer) return false;

  if (modelViewer.src) {
    try {
      const source = new URL(modelViewer.src);
      if (source?.protocol !== window.location.protocol) {
        modelViewer.src = source.href.replace(source.protocol, window.location.protocol);
      }
    } catch (error) {
      console.warn(error.message, modelViewer.src);
    }
  }

  const selectedAnimation = modelViewer.dataset.animation;

  const select = wrapper.querySelector("#variant");
  const animations = wrapper.querySelector("#animations");
  const percentageWrapper = wrapper.querySelector(".percentageWrapper");
  const percentageElement = percentageWrapper?.querySelector(".percentage");

  if (modelViewer.reveal === "interaction" && percentageWrapper) {
    percentageWrapper.style.display = "none";
  }

  modelViewer.addEventListener("load", () => {
    createSelector(select, modelViewer.availableVariants, "", variant);
    createSelector(animations, modelViewer.availableAnimations, selectedAnimation, animation);
    modelViewer.animationName = selectedAnimation;

    setTimeout(() => {
      if (percentageWrapper) {
        percentageWrapper.style.display = "none";
      }
    }, 100);
  });

  //show percentage
  if (percentageElement) {
    modelViewer.addEventListener("progress", (event) => {
      percentageWrapper.style.display = "block";
      percentageElement.innerHTML = parseInt(event.detail.totalProgress * 100) + "%";
    });
  }

  select &&
    select.addEventListener("input", (event) => {
      modelViewer.variantName = event.target.value;
    });
  animations &&
    animations.addEventListener("input", (event) => {
      modelViewer.animationName = event.target.value;
    });

  // slides
  const slides = wrapper.querySelectorAll(".slide");
  for (const slide of slides) {
    slide.addEventListener("click", (e) => {
      e.preventDefault();
      modelViewer.src = slide?.dataset?.source;
      modelViewer.poster = slide?.dataset?.poster;
      slides.forEach((element) => {
        element.classList.remove("selected");
      });
      slide.classList.add("selected");
      if (select) select.innerHTML = "";
    });
  }

  document.querySelector(".slider")?.addEventListener("beforexrselect", (ev) => {
    ev.preventDefault();
  });
  // });
}
window.modelRenderer = modelRenderer;
export default modelRenderer;
