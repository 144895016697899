import { rgbaStringToFactor } from './utils';

class ModelReader {
  constructor(model) {
    window.model = model;
    this.model = model;
    this.copy = null;
    this.thumbnailsById = new Map();
    this.thumbnail_size = 256;
    this.textures = [];
    this.texturesArray = [];
    this.textureChannels = [];
    this.defaultMaterialData = null;
    // this.init();
    this.initialized = false;
    this.sizes = [];
    this.copy = null;
    this.readyCallbacks = [];
    this.isReady = false;

    if(this.model.loaded){
      this.onLoad();
     }else {
       this.model.addEventListener('load', this.onLoad.bind(this));
     }
  }
  initialize() {
      this.isReady = true;
      this.readyCallbacks.forEach(callback => callback());
      this.readyCallbacks = [];
    }

    onReady(callback) {
      if (this.isReady) {
          callback();
      } else {
          this.readyCallbacks.push(callback);
      }
  }
  
  async onLoad() {
    this.modelUrl = this.model.src; 
    this.getDefaultMaterialData();
    
    this.thumbnailsById = await this.createThumbnails();


    if(window.pagenow){
      const loaded = await this.loadModelWithoutAppending(this.model.src+"?"+Date.now());
      this.copy = loaded.model;
      const thumbnailsById = await this.createThumbnails(new Map(), this.copy.materials);
      this.copy.thumbnailsById = thumbnailsById;
    }
    this.initialize();
  }

  getTextureId(gltfImage) {
    return gltfImage.uri ?? gltfImage.bufferView.toString();
  }

  /**
   * Retrieves stored textures, generating them if not already available.
   * @returns {Promise<Object>} - The texture data.
   */
  async getTextures() {
    if (Object.keys(this.textures).length === 0 && this.model) {
      await this.createThumbnails();
    }
    return this.textures;
  }
  async getBlobFileSize(blobUrl) {
    try {
        const response = await fetch(blobUrl);
        if (!response.ok) throw new Error("Failed to fetch the Blob URL");

        const blob = await response.blob();
        return blob.size; // Size in bytes
    } catch (error) {
        console.error("Error getting Blob file size:", error);
        return null;
    }
}

/**
   * Stores texture thumbnails in the cache.
   * @param {Map} thumbnailsById - The texture cache map.
   * @param {Object} textureInfo - The texture information.
   * @returns {Promise<string|null>} - The texture ID.
   */
  async pushThumbnail(thumbnailsById, textureInfo) {
    const { texture } = textureInfo || {};
    if (!texture || !texture.source) return null;

    const id = this.getTextureId(texture.source);
    if (!thumbnailsById.has(id)) {
      const objectUrl = await texture.source.createThumbnail(this.thumbnail_size, this.thumbnail_size);
      const size = await this.getBlobFileSize(objectUrl);
      thumbnailsById.set(id, { objectUrl, texture, size, id });
      if (!this.textures[id]) {
        this.texturesArray.push(id);
        this.textures[id] = objectUrl;
        this.sizes[id+"_"+size] = objectUrl;
      }
    }
    return id;
  }

  async createThumbnails(thumbnailsById, materials =  this.model.model?.materials) {
    if(!thumbnailsById){
      thumbnailsById = new Map();
    }
    for (const material of materials || []) {
      await material.ensureLoaded();
      const { pbrMetallicRoughness, normalTexture, emissiveTexture, occlusionTexture } = material;
      const { baseColorTexture, metallicRoughnessTexture } = pbrMetallicRoughness;
      await this.pushThumbnail(thumbnailsById, normalTexture);
      await this.pushThumbnail(thumbnailsById, emissiveTexture);
      await this.pushThumbnail(thumbnailsById, occlusionTexture);
      await this.pushThumbnail(thumbnailsById, baseColorTexture);
      await this.pushThumbnail(thumbnailsById, metallicRoughnessTexture);
    }
    // this.thumbnailsById = thumbnailsById;
    return thumbnailsById;
  }
  getAllMaterialData() {
    if (!this.model.model) {
        console.warn("Model not loaded yet.");
        return {};
    }
  
    const materialsData = {};
  
    this.model.model.materials.forEach(async (material) => {
        await material.ensureLoaded();

        const materialName = material.name || `Material_${Math.random().toString(36).substr(2, 5)}`; // Ensure a unique key if no name exists
  
        materialsData[materialName] = {
            baseColor: {
                texture: {
                  name: material.pbrMetallicRoughness.baseColorTexture?.texture?.source.name || null,
                  uri: material.pbrMetallicRoughness.baseColorTexture?.texture?.source.uri || null
                },
                factor: material.pbrMetallicRoughness.baseColorFactor || [1, 1, 1, 1], // Default to white
            },
            metallicRoughness: {
                texture: {
                  name: material.pbrMetallicRoughness.metallicRoughnessTexture?.texture?.source.name || null,
                  uri: material.pbrMetallicRoughness.metallicRoughnessTexture?.texture?.source.uri || null,
                },
                factor: [
                    material.pbrMetallicRoughness.metallicFactor || 1, 
                    material.pbrMetallicRoughness.roughnessFactor || 1
                ]
            },
            normal: {
                texture: {
                  uri: material.normalTexture?.texture?.source.uri || null,
                  name: material.normalTexture?.texture?.source.name || null
                },
            },
            emissive: {
                texture: {
                  name: material.emissiveTexture?.texture?.source.name || null,
                  uri: material.emissiveTexture?.texture?.source.uri || null
                },
                factor: material.emissiveFactor || [0, 0, 0], // Default to black
            },
            occlusion: {
                texture: {
                  name: material.occlusionTexture?.texture?.source.name || null,
                  uri: material.occlusionTexture?.texture?.source.uri || null
                }
            },
        };
    });
    
    return materialsData;
  }

  getDefaultMaterialData(){
    if(!this.defaultMaterialData) {
      this.defaultMaterialData = this.getAllMaterialData();
    }
    return this.defaultMaterialData;
  }

  /**
   * Updates the texture and factor of a material for a specific channel.
   *
   * @param {THREE.Material} material - The material to update.
   * @param {string} channel - The channel to update (e.g., 'baseColor', 'metallicRoughness', 'normal', 'emissive', 'occlusion').
   * @param {string|null} textureUri - The URI of the new texture. If null, removes the current texture.
   * @param {string} factor - The new factor for the color channels.
   * @param {string} textureName - The name of the new texture.
   *
   * @returns {void}
   */
  async updateMaterialTextureAndFactor ( material, channel,  factor, textureUri = null, textureName = `texture_${Date.now()}`) {
      if (!this.model?.model || !material) {
        console.warn("Model or Material not found.");
        return;
      }

      if (factor) {
        if (channel === "baseColor") material.pbrMetallicRoughness.setBaseColorFactor(rgbaStringToFactor(factor));
        if(channel === "metallicRoughness"){
          material.pbrMetallicRoughness.setMetallicFactor(factor[0]);
          material.pbrMetallicRoughness.setRoughnessFactor(factor[1]);
        }
        if (channel === "emissive") material.setEmissiveFactor(rgbaStringToFactor(factor));
      }

      if(textureUri === 'default'){
        if (channel.includes("base") || channel.includes("metallic")) {
          const texture = this.copy.getMaterialByName(material.name).pbrMetallicRoughness[channel + "Texture"].texture;
          material.pbrMetallicRoughness[channel + "Texture"].setTexture(texture);
        } else {
          const texture = this.copy.getMaterialByName(material.name)[channel + "Texture"].texture;
            material[channel + "Texture"].setTexture(texture);
        }
        console.log(`Removed texture from '${channel}' of material '${material.name}'.`);
        return;
      }

      // Handle removing texture if textureUri is null
      if (!textureUri) {
          if (channel.includes("base") || channel.includes("metallic")) {
              material.pbrMetallicRoughness[channel + "Texture"].setTexture(null);
          } else {
              material[channel + "Texture"].setTexture(null);
          }
          console.log(`Removed texture from '${channel}' of material '${material.name}'.`);
          return;
      }

      // Create and apply new texture
      try {
        if(typeof textureUri === 'string'){
          textureUri = await this.model.createTexture(textureUri);
          textureUri.name =textureName;
        }
          // const texture = await this.model.createTexture(textureUri);
          // texture.name = textureName; // Unique name

          if (channel.includes("base") || channel.includes("metallic")) {
              material.pbrMetallicRoughness[channel + "Texture"].setTexture(textureUri);
          } else {
              material[channel + "Texture"].setTexture(textureUri);
          }

          console.log(`Applied new texture ${textureName} to '${channel}' of material '${material.name}'.`);
      } catch (error) {
          console.error("Error creating texture:", error);
      }
  }


  /**
   * Applies textures and factors to materials based on the provided applied textures object.
   * If a material name and channel are provided, applies the texture and factor to a specific material and channel.
   * If only applied textures are provided, applies the textures and factors to all materials and channels.
   *
   * @param {Object} appliedTextures - An object containing the textures and factors to apply.
   * @param {string} [materialName=null] - The name of the material to apply the texture and factor to.
   * @param {string} [channel=null] - The channel (e.g., 'baseColor', 'metallicRoughness', 'normal', 'emissive', 'occlusion') to apply the texture and factor to.
   *
   * @returns {void}
   */
  async applyTexture(appliedTextures, materialName = null, channel = null) {
    window.modelReader = this;
    if(!appliedTextures) return;

    if (materialName && channel) {
      const { name, url} = appliedTextures[materialName]?.[channel]?.texture || {};
      const material = this.model?.model?.getMaterialByName(materialName);
      let textureImage = url || this.getTextureByName(name);
      // let textureImage = appliedTextures[materialName]?.[channel]?.texture?.url;
      if(!appliedTextures[materialName]?.[channel]?.texture){
        textureImage = 'default';
      }
      const factor = appliedTextures[materialName]?.[channel]?.factor || null;
      this.updateMaterialTextureAndFactor(material, channel,  factor, textureImage,name);
      return;
    }

    Object.entries(appliedTextures).forEach(([matName, channels]) => {
      const material = this.model?.model?.getMaterialByName(matName);
      if (!material) {
        console.warn(`Material '${matName}' not found.`);
        return;
      }

      Object.keys(channels).forEach((ch) => {
        const { name, url } = channels[ch]?.texture || {};
        let textureImage = url || this.getTextureByName(name); //|| textures[this.texturesArray[index]] || textures[name];
        if(!channels[ch]?.texture){
          textureImage = 'default';
        }
        const factor = channels[ch]?.factor || null;
        
        this.updateMaterialTextureAndFactor(material, ch, factor,textureImage, name);
      });
    });
  }

  async shakeMaterialFactor(material, duration = 500) {
    if (!material) return;
    if(typeof material === 'string'){
      material = this.getMaterialByName(material);
    }
    const originalFactor = material.pbrMetallicRoughness.baseColorFactor.slice();
    material.pbrMetallicRoughness.setBaseColorFactor([1, 0, 0, 1]);
    setTimeout(() => {
      material.pbrMetallicRoughness.setBaseColorFactor(originalFactor);
    }, duration);
  }

  /**
   * Retrieves the URI of a texture by name.
   */
  getTextureURIByName(name) {
    return name.includes("blob") ? name : this.textures[name] || null;
  }

  getMaterialByName(name) {
    return this.model.model.getMaterialByName(name);
  }

  getTexturesArray(){
    return this.texturesArray;
  }

  getTextureChannels(){
    return [
      {
        label: "Base Color",
        value: "baseColor",
      },
      {
        label: "Metallic Roughness",
        value: "metallicRoughness",
      },
      {
        label: "Normal Map",
        value: "normal",
      },
      {
        label: "Emissive",
        value: "emissive",
      },
      {
        label: "Occlusion",
        value: "occlusion",
      },
    ];
  }

  getMaterialsNameForSelectControl() {
    if(!this.model.loaded) {
      console.warn('Model not loaded yet');
      return [];
    }
    if (Array.isArray(this.model.model.materials)) {
      return this.model.model.materials.map((item) => {
        const label = item.name.replace("_mtl", "").replaceAll("_", " ");
        return { label, value: item.name };
      });
    }
    return [];
  }
  getFirstMaterialName(){
    if(!this.model.loaded || !this.model.model) {
      console.warn('Model not loaded yet');
      return null;
    }
    if (Array.isArray(this.model.model.materials)) {
      return this.model.model.materials[0].name;
    }
    return null;
  }

  getTextureById( textureId) {
    return this.thumbnailsById.get(textureId);
}

  async downloadTexture( textureId, size = 512) {
    const {texture = null} = this.getTextureById(textureId);
    if (!texture) {
        console.error("Texture not found!");
        return;
    }
    const objectUrl = await texture.source.createThumbnail(size, size)

    const canvas = document.createElement("canvas");
    canvas.width = size;
    canvas.height = size;
    const ctx = canvas.getContext("2d");

    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = objectUrl;

    image.onload = () => {
        ctx.drawImage(image, 0, 0, size, size);
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = `texture_${textureId}.png`;
        link.click();
    };

    image.onerror = () => {
        console.error("Failed to load texture image.");
    };
}

  // async createAndApplyTexture (material, channel, value, name = "raju" + new Date().getTime()) {
  //   const texture = await this.model.createTexture(value);
  //   texture.name = name;
  //   if (channel.includes("base") || channel.includes("metallic")) {
  //     material.pbrMetallicRoughness[channel+'Texture'].setTexture(texture);
  //   } else {
  //     material[channel+'Texture'].setTexture(texture);
  //   }
  // }

  deepCloneModel() {
    const clonedModel = this.model.originalModel.clone(true); // Clones recursively

    clonedModel.traverse((node) => {
        if (node.isMesh) {
            // Clone material (to avoid sharing)
            if (node.material) {
                node.material = node.material.clone();
            }

            // Clone geometry (if needed)
            if (node.geometry) {
                node.geometry = node.geometry.clone();
            }

            // Clone textures (to avoid sharing)
            if (node.material.map) {
                node.material.map = node.material.map.clone();
                node.material.map.needsUpdate = true;
            }
        }
    });

    return clonedModel;
  }

  async loadModelWithoutAppending(src) {
    return new Promise((resolve, reject) => {
      const modelViewer = document.createElement('model-viewer');
      modelViewer.src = src;
      modelViewer.setAttribute('loading', 'eager');
      modelViewer.style.display = 'none'; // Hide it from view
      modelViewer.style.height = '0'; // Hide it from view
      document.body.appendChild(modelViewer); // Append to DOM (but hidden)

      modelViewer.addEventListener('load', () => {
          resolve(modelViewer);
          // Remove it after loading if you don't need it anymore
          // modelViewer.remove();
      });

      modelViewer.addEventListener('error', (e) => {
          reject(new Error(`Model failed to load: ${e.message}`));
      });
  });
}
/**
 * Retrieves the texture object, its name, and URI by material name and channel.
 *
 * @param {string} materialName - The name of the material.
 * @param {string} channel - The channel (e.g., 'baseColor', 'metallicRoughness', 'normal', 'emissive', 'occlusion').
 *
 * @returns {Object|null} - An object containing the texture object, its name, and URI.
 *   If the material or texture is not found, returns null.
 */
getTextureByName(textureName) {
  // if (!this.copy) {
  //     console.warn("Model copy is not generated.");
  //     return null;
  // }

  if(this.copy?.thumbnailsById){
    return this.copy.thumbnailsById.get(textureName).texture;
  }
  if(this.thumbnailsById){
    return this.thumbnailsById.get(textureName)?.texture;
  }
  return null
}

  createCopy(){
    // const 
  }


  
}

export default ModelReader;
