// import { withSelect } from "@wordpress/data";

// const Close = ({ isSidebarOpened }) => {
//   return (
//     <svg id="closeBtn" className={isSidebarOpened ? "sidebar-open" : "sidebar-closed"} width="34px" height="34px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//       <path fill="none" stroke="#333" strokeWidth="2" d="M7,7 L17,17 M7,17 L17,7"></path>
//     </svg>
//   );
// };

// export default withSelect((select) => {
//   const { isEditorSidebarOpened } = select("core/edit-post");
//   return {
//     isSidebarOpened: isEditorSidebarOpened(),
//   };
// })(Close);

const Close = () => {
  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      // / Safari /;
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      // / IE11 /;
      document.msExitFullscreen();
    }
  }
  return (
    <svg id="closeBtn" onClick={closeFullscreen} className="sidebar-open" width="34px" height="34px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path fill="none" stroke="#f2f2f2" strokeWidth="2" d="M7,7 L17,17 M7,17 L17,7"></path>
    </svg>
  );
};

export default Close;
